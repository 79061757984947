import ToggleVisibilityController from './toggle_visibility_controller'

export default class extends ToggleVisibilityController {
  static targets = ['input']

  populate({ params: { values } }) {
    Object.entries(values).forEach(([key, value]) => {
      const input = this.inputTargets.find((inp) => inp.name.includes(key))

      if (!input) return

      input.value = value
    })
  }

  clear() {
    this.inputTargets.forEach((input) => {
      input.value = null

      input.dispatchEvent(new Event('change'))
    })
  }

  hiddenValueChanged(value) {
    super.hiddenValueChanged(value)

    const inputs = [...this.containerTarget.querySelectorAll('input')]

    inputs.forEach((input) => (input.disabled = value))
  }
}
