/* eslint-env jquery */
/* global loadLazyPartial */

$(document).on('turbo:load', function () {
  'use strict'

  var $$$schoolSearchResponse = null
  $('#search').on('keyup', function (e) {
    if (e.key !== 'Tab') {
      var query = $(this).val()

      var $this = $(this)
      var $target = $('#search-results')
      var path = `${$this.data('path')}?query=${query}`

      $target.show()
      var before = $this.val()
      setTimeout(function () {
        if (before === $this.val()) {
          if ($$$schoolSearchResponse !== null) $$$schoolSearchResponse.abort()

          $$$schoolSearchResponse = loadLazyPartial(path, $target, true, false)
        }
      }, 300)
    }
  })

  $('#search').on('focus', function () {
    $('#search-container').closest('.navbar-nav').addClass('search-active')
    var $target = $('#search-results')
    $target.show()
  })

  $('#search').on('focusout', function () {
    if ($(this).val() === '') {
      var $target = $('#search-results')
      $target.hide()
    }
  })

  $('.form-field svg.fa-xmark, .navbar-utility .navbar-nav .dropdown').on('click', function () {
    $('input#search').val('')
    $('#search-container').closest('.navbar-nav').removeClass('search-active')
    var $target = $('#search-results')
    $target.hide()
    $('.form-field svg.fa-xmark').hide()
  })
})
