import Flatpickr from 'stimulus-flatpickr'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'

export default class extends Flatpickr {
  connect() {
    if (this.element.dataset.flatpickrMode === 'month') {
      this.config = {
        plugins: [
          new monthSelectPlugin({
            shorthand: 'true',
            altFormat: 'F Y',
          }),
        ],
      }
    }

    super.connect()
  }
}
