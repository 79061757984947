import { useClickOutside } from 'stimulus-use'
import ApplicationController from '../../javascript/controllers/application_controller'

export default class extends ApplicationController {
  static targets = ['tray', 'dialog', 'close', 'open']

  connect() {
    useClickOutside(this, { element: this.dialogTarget, events: ['mousedown'] })

    this.positionDialog()
  }

  open() {
    this.trayTarget.classList.add('open')
    this.closeTarget.classList.remove('hidden')
    this.openTarget.classList.add('hidden')

    document.body.classList.add('overflow-hidden')
  }

  close() {
    if (!this.trayTarget.classList.contains('open')) return

    this.trayTarget.classList.remove('open')
    this.trayTarget.classList.add('going')

    document.body.classList.remove('overflow-hidden')

    this.trayTarget.addEventListener(
      'transitionend',
      () => {
        this.trayTarget.classList.remove('going')
      },
      { once: true },
    )

    this.trayTarget.classList.remove('open')
    this.closeTarget.classList.add('hidden')
    this.openTarget.classList.remove('hidden')
  }

  disconnect() {
    this.close()
  }

  ///
  /// private
  ///

  positionDialog() {
    const closeParent = this.closeTarget.parentElement
    const rect = closeParent.getBoundingClientRect()

    this.dialogTarget.style.top = `${rect.bottom}px`
    this.dialogTarget.style.maxHeight = `calc(100dvh - ${rect.bottom}px - 10%)`
  }

  clickOutside(event) {
    if (getComputedStyle(this.dialogTarget).visibility === 'hidden') return

    if (this.isUserback(event)) return

    if (this.isModal(event)) return

    const calendars = Array.from(document.querySelectorAll('.flatpickr-calendar'))
    const hasTarget = calendars.filter((calendar) => calendar.contains(event.target))

    if (hasTarget.length !== 0) return

    this.close()
  }

  isModal({ target }) {
    return target.closest('.modal')
  }

  isUserback({ target }) {
    if (target.tagName === 'UBDIV') return true

    const toolbar = target.closest('utoolbar')

    if (toolbar) return true

    const overlay = target.closest('.userback-overlay')

    if (overlay) return true

    return false
  }
}
